<template>
  <div class="row">

    <div class="col-lg-8 m-auto">
      <v-card :title="$t('auth.verify_email')">
        <template v-if="message">


            <v-card>
                <div class="alert alert-success" role="alert">
                  {{ message }}
                </div>
              <v-form @submit.prevent="send" @keydown="form.onKeydown($event)" v-model="valid">

                <v-card-text>
                  <v-alert v-if="status"  outlined prominent border="left"  type="success">
                    {{status}}
                  </v-alert>
                    <v-text-field
                      v-model="email"
                      disabled
                      label="Email"
                    ></v-text-field>
                    <v-textarea
                      v-model="form.reason"
                      label="Reson"
                      solo
                    ></v-textarea>

                </v-card-text>

                <v-card-actions>
                    
                    <v-btn type="submit" rounded color="primary" :disabled="!valid && !url" :loading="form.busy">
                      Unsubscribe
                    </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>

        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{ error || $t('auth.failed_to_verify_email') }}
          </div>

        </template>
      </v-card>
    </div>
  </div>
</template>

<script>

import Form from 'vform'
import i18n from '@/i18n'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  middleware: 'auth',

  metaInfo () {
    return { title: i18n.t('auth.unsubscribes') }
  },

  async beforeRouteEnter (to, from, next) {
      try {
        const { data } = await this.$http.post(`/api/unsubscribes/${to.params.id}?${qs(to.query)}`)
        next(vm => { 
          vm.message = data.message 
          vm.email = data.email
          vm.url = data.url
          })
      } catch (e) {
        next(vm => { 
          vm.error = e.data.message
          })
      }

  },

  data: () => ({
    error: '',
    message: '',
    email: null,
    reason: '',
    url: null,
    valid: false,
    form: new Form({
      reason: ''
    }),
    status: ''
  }),
  methods: {
    async send () {
      await this.$http.get('sanctum/csrf-cookie')
      const { data } = await this.form.post(this.url)
      this.status = data.message
      this.url = false;
      this.valid = false;
      this.form.reset()
    }
  }
}
</script>
